import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { InlineWidget as CalendlyWidget } from 'react-calendly'
import classNames from 'classnames'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAgency } from '../hooks/use-agency'
import Layout from '../components/layout'
import Input from '../theming/Input'
import { websiteApiClient } from '../utils/api'
import { Modal } from '../components/modal'
import { useLocale } from '../hooks/use-locale'

const ScheduleVisitSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(<FormattedMessage id="This value should not be blank." />),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(<FormattedMessage id="This value should not be blank." />),
  email: Yup.string()
    .email(<FormattedMessage id="This value is not a valid email address." />)
    .required(<FormattedMessage id="This value should not be blank." />),
  phone: Yup.string().required(
    <FormattedMessage id="This value should not be blank." />
  ),
})

export default function ScheduleVisitPage(props) {
  const locale = useLocale()
  const { propertyId, appointmentServiceUrl } = props.location.state ?? {}
  const [scheduled, setScheduled] = React.useState(false)
  const { agency } = useAgency()
  const [showSelectTimeModal, setShowSelectTimeModal] = React.useState(false)
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    submitCount,
    handleSubmit,
  } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      locale,
    },
    onSubmit: async () => {
      setShowSelectTimeModal(true)
    },
    validationSchema: ScheduleVisitSchema,
  })

  const handleScheduleVisit = async () => {
    if (propertyId) {
      await websiteApiClient.post('/schedule-visit', {
        ...values,
        property_id: propertyId,
      })
      setScheduled(true)
    }
  }

  const submitted = submitCount > 0

  return (
    <Layout {...props} title="page.schedule-visit">
      <div className="c-row c-row--beta">
        <div className="o-container">
          {isCalendly(appointmentServiceUrl) ? (
            <CalendlyModal
              isOpen={showSelectTimeModal}
              onClose={() => setShowSelectTimeModal(false)}
              values={values}
              url={appointmentServiceUrl}
              onBooked={handleScheduleVisit}
            />
          ) : (
            isYouCanBookMe(appointmentServiceUrl) && (
              <YouCanBookMeModal
                isOpen={showSelectTimeModal}
                onClose={() => setShowSelectTimeModal(false)}
                values={values}
                url={appointmentServiceUrl}
                onBooked={handleScheduleVisit}
              />
            )
          )}
          <form
            method="post"
            className="o-grid o-grid--gutter"
            id="scheduleVisitForm"
            onSubmit={handleSubmit}
          >
            <div className="o-grid__item u-3-of-5-bp4 u-push-1-of-5-bp4">
              <h2 className="section-title">
                <FormattedMessage id="page.schedule-visit" />
              </h2>
              {scheduled ? (
                <div className="c-alert-box c-alert-box--success">
                  <p>
                    <FormattedMessage id="schedule-visit.form.success-message" />
                  </p>
                </div>
              ) : (
                <div className="scheduleVisitFormFields">
                  <div className="panel">
                    <div className="panel__body">
                      <div className="o-grid o-grid--gutter">
                        <div className="o-grid__item u-1-of-2-bp3  c-input-holder">
                          <label className="c-label" htmlFor="name">
                            <FormattedMessage id="name" />
                          </label>
                          <Input
                            type="text"
                            className={classNames(
                              'c-input-text c-input-text--md',
                              {
                                'has-error': errors.first_name && submitted,
                              }
                            )}
                            name="first_name"
                            id="name"
                            value={values.first_name}
                            onChange={handleChange}
                          />
                          {errors.first_name && submitted && (
                            <ErrorMessage message={errors.first_name} />
                          )}
                        </div>
                        <div className="o-grid__item u-1-of-2-bp3  c-input-holder">
                          <label className="c-label" htmlFor="familyName">
                            <FormattedMessage id="family-name" />
                          </label>
                          <Input
                            type="text"
                            className={classNames(
                              'c-input-text c-input-text--md',
                              {
                                'has-error': errors.last_name && submitted,
                              }
                            )}
                            name="last_name"
                            id="familyName"
                            value={values.last_name}
                            onChange={handleChange}
                          />
                          {errors.last_name && submitted && (
                            <ErrorMessage message={errors.last_name} />
                          )}
                        </div>
                      </div>
                      <div className="o-grid o-grid--gutter">
                        <div className="o-grid__item u-1-of-2-bp3  c-input-holder">
                          <label className="c-label" htmlFor="email">
                            <FormattedMessage id="email-address" />
                          </label>
                          <FormattedMessage id="placeholders.email-address">
                            {(placeholder) => {
                              return (
                                <Input
                                  type="email"
                                  className={classNames(
                                    'c-input-text c-input-text--md',
                                    {
                                      'has-error': errors.email && submitted,
                                    }
                                  )}
                                  name="email"
                                  id="email"
                                  placeholder={placeholder}
                                  value={values.email}
                                  onChange={handleChange}
                                />
                              )
                            }}
                          </FormattedMessage>
                          {errors.email && submitted && (
                            <ErrorMessage message={errors.email} />
                          )}
                        </div>
                        <div className="o-grid__item u-1-of-2-bp3  c-input-holder">
                          <label className="c-label" htmlFor="phone">
                            <FormattedMessage id="phone-number" />
                          </label>
                          <FormattedMessage
                            id="placeholders.phone-number"
                            values={{
                              phone: '+32 XXX XXX XXX',
                            }}
                          >
                            {(placeholder) => {
                              return (
                                <Input
                                  type="tel"
                                  className={classNames(
                                    'c-input-text c-input-text--md',
                                    {
                                      'has-error': errors.phone && submitted,
                                    }
                                  )}
                                  name="phone"
                                  id="phone"
                                  placeholder={placeholder}
                                  value={values.phone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              )
                            }}
                          </FormattedMessage>
                          {errors.phone && submitted && (
                            <ErrorMessage message={errors.phone} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="scheduleVisitFormFields">
                    <p>
                      <button
                        type="submit"
                        className="c-button c-button--md c-button--alpha c-button--block"
                      >
                        <FormattedMessage id="schedule-visit.form.submit" />
                      </button>
                    </p>
                    <p className="u-color-neutral-base">
                      <small>
                        <FormattedHTMLMessage
                          id="personal-updates.disclaimer"
                          values={{
                            name: agency.name,
                          }}
                        />
                      </small>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}

const ErrorMessage = ({ message }) => (
  <span className="c-alert-inline c-alert-inline--error"> {message} </span>
)

function isCalendly(url) {
  return /^(https?:\/\/)?calendly\.com\/[-_a-z0-9\/]+$/.test(url)
}

function isYouCanBookMe(url) {
  return /^(https?:\/\/)?[-a-z0-9]+\.youcanbook.me\/?$/.test(url)
}

function CalendlyModal({ values, isOpen, onClose, url, onBooked }) {
  React.useEffect(() => {
    if (isOpen) {
      onBooked()
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <CalendlyWidget
        url={url}
        prefill={{
          name: [values.first_name, values.last_name].join(' '),
          firstName: values.first_name,
          lastName: values.last_name,
          email: values.email,
        }}
        pageSettings={{
          backgroundColor: 'red',
          primaryColor: 'red',
          textColor: 'red',
        }}
      />
    </Modal>
  )
}

function YouCanBookMeModal({ values, isOpen, onClose, url, onBooked }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Select time">
      <YouCanBookMeWidget
        url={url}
        prefill={{
          name: [values.first_name, values.last_name].join(' '),
          firstName: values.first_name,
          lastName: values.last_name,
          email: values.email,
        }}
        onBooked={onBooked}
      />
    </Modal>
  )
}

function YouCanBookMeWidget({ url, prefill, onBooked }) {
  const builtUrl = React.useMemo(() => {
    const builtUrl = new URL(url)
    builtUrl.searchParams.append('FNAME', prefill.firstName)
    builtUrl.searchParams.append('LNAME', prefill.lastName)
    builtUrl.searchParams.append('EMAIL', prefill.email)
    return builtUrl
  }, [url, prefill])

  return (
    <iframe
      onLoad={(evt) => {
        onBooked()
      }}
      title="YouCanBookMe embedded widget"
      src={`${builtUrl.toString()}&noframe=true&skipHeaderFooter=true`}
      style={{
        width: '100%',
        height: 'calc(100vh - 150px)',
        border: 0,
        backgroundColor: 'transparent',
        margin: '20px 0',
      }}
      frameBorder="0"
      allowTransparency="true"
    />
  )
}
